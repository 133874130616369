import { useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useStore } from '../../store';
import CustomerActions from './CustomerActions';

const Customer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isLoading = useStore((state) => state.isLoading);
  const callGetCustomersAPI = useStore((state) => state.getCustomersApi);
  const customers = useStore((state) => state.customers);

  useEffect(() => {
    callGetCustomersAPI();
  }, []);
  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      // type: 'number',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Access Level',
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            // p="5px"
            display="flex"
            justifyContent="center"
            // backgroundColor={
            //   role === 'student'
            //     ? colors.greenAccent[600]
            //     : // : access === 'manager'
            //       // ? colors.greenAccent[700]
            //       colors.greenAccent[700]
            // }
            borderRadius="4px"
          >
            {role === 'student' && <SchoolOutlinedIcon />}
            {role === 'public' && <Person2OutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
              {/* {role} */}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            gap="12px"
            borderRadius="4px"
          >
            {/* <CategoryBtnDelete cat={{ id, name }} />
            <CategoryBtnEdit id={id} /> */}
            <CustomerActions params={{ id }} />
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="CUSTOMER" subtitle="Managing Customers" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.redAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.redAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          rows={customers}
          columns={columns}
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default Customer;
