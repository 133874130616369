import { Box, Typography, IconButton, Avatar, useTheme } from '@mui/material';
import { useContext } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store';
import { ColorModeContext, tokens } from '../../theme';
import InputBase from '@mui/material/InputBase';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SearchIcon from '@mui/icons-material/Search';
// import { useLogoutMutation } from '../../features/auth/adminApiSlice';
// import { logout } from '../../features/auth/authSlice';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const authAdminLogoutApiCall = useStore((state) => state.authAdminLogoutApi);

  // const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
  const adminInfo = useStore((state) => state.auth);

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    // try {
    //   await logoutApiCall().unwrap();
    //   dispatch(logout());
    //   navigate('/login');
    // } catch (err) {
    //   console.log(err);
    // }
    authAdminLogoutApiCall();
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: `${
          theme.palette.mode === 'dark' ? colors.primary[500] : '#f5f5f5'
        }`,
        zIndex: '100',
      }}
      display="flex"
      justifyContent="space-between"
      p={2}
    >
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
        {!adminInfo && (
          <img
            src={`../../assets/D818 - SVG.svg`}
            alt="D818 Logo"
            width="60px"
          />
        )}
      </Box>

      {/* ICONS */}
      <Box display="flex" gap="20px">
        <IconButton title="Color Theme" onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {adminInfo && (
          <>
            {/* <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton> */}
            <IconButton onClick={() => navigate('/profile')} title="My Profile">
              {/* <Box> */}
              {/* <PersonOutlinedIcon /> */}
              <Avatar src={adminInfo.img || `../../assets/D818 - SVG.svg`} />
              {/* <Typography>{adminInfo && adminInfo.firstName}</Typography> */}
              {/* </Box> */}
            </IconButton>
            <IconButton title="Logout" onClick={logoutHandler}>
              <LogoutOutlinedIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
