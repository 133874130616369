import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material';
import Select from '@mui/material/Select';
import { tokens } from '../../theme';

export default function MenuCategory({ category, categories, onSelect }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  return (
    <div>
      <FormControl
        sx={{
          marginBottom: '18px',
          minWidth: '50%',
        }}
      >
        <InputLabel id="demo-simple-select-helper-label">Categories</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={category}
          label="Categories"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {categories &&
            categories.map((cat) => (
              <MenuItem key={cat._id} value={cat._id}>
                {cat.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
