import * as React from 'react';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Avatar, useTheme } from '@mui/material';
import { tokens } from '../../theme';

export default function CustomerProfileCard({ data }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Card
      sx={{
        // maxWidth: 300,
        backgroundColor: `${
          theme.palette.mode === 'dark' ? colors.primary[500] : '#f3f3f3'
        }`,
      }}
    >
      <Avatar
        sx={{
          width: 180,
          height: 180,
          margin: '12px auto',
          //   border: `1px solid ${colors.redAccent[500]}`,
        }}
        src={data.img}
      />
      {/* <CardMedia
        sx={{ height: 250, margin: '5px', borderRadius: '50%' }}
        // image="https://media.fooducate.com/comments/images/62815207-5157-AE28-4C64-B8A2C34F0426-12231.jpeg"
        image={data.img}
        title={data.fullName}
      /> */}
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography
          sx={{ color: colors.greenAccent[500] }}
          gutterBottom
          variant="h3"
          component="div"
          fontWeight="bold"
        >
          {data.fullName}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '8px',
            gap: '5px',
          }}
        >
          <EmailIcon sx={{ color: colors.grey[500] }} />
          <Typography variant="body1" color="text.secondary">
            {data.email}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '8px',
            gap: '5px',
          }}
        >
          <LocationOnIcon sx={{ color: colors.grey[500] }} />
          <Typography variant="body1" color="text.secondary">
            {data.address}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '8px',
            gap: '5px',
          }}
        >
          <PhoneIcon sx={{ color: colors.grey[500] }} />
          <Typography variant="body1" color="text.secondary">
            {data.phone}
          </Typography>
        </Box>
        {data.gender && data.dob && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '24px' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '8px',
                gap: '5px',
              }}
            >
              <CakeIcon sx={{ color: colors.grey[500] }} />
              <Typography variant="body1" color="text.secondary">
                {moment(data.dob).format('MMM Do YY')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '8px',
                gap: '5px',
              }}
            >
              <PersonIcon sx={{ color: colors.grey[500] }} />
              <Typography variant="body1" color="text.secondary">
                {data.gender}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ backgroundColor: colors.redAccent[500] }}>
        <Button
          sx={{ backgroundColor: colors.redAccent[500], color: '#f3f3f3' }}
          size="small"
        >
          {data.role}
        </Button>
        {/* <Button
          sx={{ backgroundColor: colors.greenAccent[500], color: '#f3f3f3' }}
          size="small"
        >
          {data.category}
        </Button> */}
      </CardActions>
    </Card>
  );
}
