import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import axios from 'axios';
import { toast } from 'react-toastify';

const LIVE_URL = `https://d818-backend-a3bb3967e45d.herokuapp.com/api`;
const API_URL = `https://d818-backend.onrender.com/api`;
const LOCAL_URL = `http://localhost:3000/api`;
let t;
const bearerToken = {
  headers: {
    Authorization: `Bearer ${t}`,
  },
};

const store = (set) => ({
  auth: localStorage.getItem('adminInfo')
    ? JSON.parse(localStorage.getItem('adminInfo'))
    : null,
  admins: [],
  meals: [],
  customers: [],
  categories: [],
  campuses: [],
  reviews: [],
  productReviews: [],
  feedback: [],
  orders: [],
  outOfBoundOrders: [],
  isLoading: false,
  authAdminApi: async (payload) => {
    try {
      const apiResponse = await axios.post(
        `${LIVE_URL}/admins/login`,
        payload
      );
      set((state) => {
        // state.meals.push(apiResponse.data);
        state.auth = apiResponse.data;
        localStorage.setItem('adminInfo', JSON.stringify(apiResponse.data));
      });
      toast.success('Admin login successfully');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  authAdminLogoutApi: async () => {
    try {
      await axios.post(`${LIVE_URL}/admins/logout`);
      set((state) => {
        state.auth = null;
        localStorage.removeItem('adminInfo');
      });
      toast.success('Admin logout successfully');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getAdminsApi: async () => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;

    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/admins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        // state.categories = apiResponse.data;
        state.admins = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  createAdminApi: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      const apiResponse = await axios.post(`${LIVE_URL}/admins`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        state.admins.push(apiResponse.data);
      });
      toast.success('Admin created');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  updateAuthAdminProfileAPI: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/admins/profile`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.auth = apiResponse.data;
        localStorage.setItem('adminInfo', JSON.stringify(apiResponse.data));
        state.isLoading = false;
      });
      toast.success('Profile Updated');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  createMealApi: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      const apiResponse = await axios.post(`${LIVE_URL}/products`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        state.meals.push(apiResponse.data);
      });
      toast.success('Meal created');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  createCategoryApi: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      const apiResponse = await axios.post(`${LIVE_URL}/categories`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        // state.categories.push(apiResponse.data)
        state.categories = [...state.categories, apiResponse.data];
      });
      toast.success('Category created');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  createCampusApi: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      const apiResponse = await axios.post(`${LIVE_URL}/campuses`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        // state.categories.push(apiResponse.data)
        state.campuses = [...state.campuses, apiResponse.data];
      });
      toast.success('Campus created');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getMealsApi: async (queryParams) => {
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/products/all`, {
        params: queryParams,
      });
      set((state) => {
        state.meals = apiResponse.data;
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  },
  getCustomersApi: async () => {
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/customers`);
      set((state) => {
        // state.categories = apiResponse.data;
        state.customers = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getCategoriesApi: async () => {
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/categories`);
      set((state) => {
        // state.categories = apiResponse.data;
        state.categories = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getCampusesApi: async () => {
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/campuses/get-campuses`);
      set((state) => {
        // state.campuses = apiResponse.data;

        state.campuses = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getFeedbackApi: async () => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/feedback`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        // state.categories = apiResponse.data;
        state.feedback = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getReviewApi: async () => {
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/reviews`);
      set((state) => {
        // state.categories = apiResponse.data;
        state.reviews = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getReviewByProductIdApi: async (payload) => {
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(
        `${LIVE_URL}/reviews/${payload}/reviews`
      );
      set((state) => {
        // state.categories = apiResponse.data;
        state.productReviews = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getOrdersApi: async () => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        // state.categories = apiResponse.data;
        state.orders = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  getOutOfBoundOrdersApi: async () => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.get(`${LIVE_URL}/out-of-bound`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        // state.categories = apiResponse.data;
        state.outOfBoundOrders = [...apiResponse.data];
        state.isLoading = false;
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  updateMealImageAPI: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    const id = payload.get('_id');

    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/products/${id}/product-image`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.meals = state.meals.map((meal) => {
          if (meal._id === payload._id) {
            return payload;
          }
          return meal;
        });
        state.isLoading = false;
      });
      toast.success('Meal Photo Updated');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  updateMealAPI: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/products/${payload._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.meals = state.meals.map((meal) => {
          if (meal._id === payload._id) {
            return payload;
          }
          return meal;
        });
        state.isLoading = false;
      });
      toast.success('Meal Updated');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  updateCategoryAPI: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/categories/${payload._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.categories = state.categories.map((cat) => {
          if (cat._id === payload._id) {
            return payload;
          }
          return cat;
        });
        state.isLoading = false;
      });
      toast.success('Category Updated');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error);
    }
  },
  updateCampusAPI: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/campuses/${payload._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.campuses = state.campuses.map((cat) => {
          if (cat._id === payload._id) {
            return payload;
          }
          return cat;
        });
        state.isLoading = false;
      });
      toast.success('Campus Updated');
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error); // Confirmed
    }
  },
  updateCampusVisibilityAPI: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/campuses/${payload}/update-visibility`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.campuses = state.campuses.map((cat) => {
          if (cat._id === payload) {
            return apiResponse?.data;
          }
          return cat;
        });
        state.isLoading = false;
      });
      toast.success(
        apiResponse?.data.isVisible
          ? `Campus Visibility is enabled`
          : `Campus visibility is disabled`
      );
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error); // Confirmed
    }
  },
  updateDeliveryStatus: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/orders/${payload}/update-delivery-status`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.orders = state.orders.map((order) => {
          if (order._id === payload) {
            return { ...order, deliveryStatus: apiResponse?.data?.message };
          }
          return order;
        });
        state.isLoading = false;
      });
      toast.success(
        // apiResponse?.data.isVisible
        //   ? `Campus Visibility is enabled`
        //   : `Campus visibility is disabled`
        'Delivery Status Updated!'
      );
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error); // Confirmed
    }
  },
  updateOutOfBoundDeliveryStatus: async (payload) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.put(
        `${LIVE_URL}/out-of-bound/${payload}/update-delivery-status`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      set((state) => {
        state.outOfBoundOrders = state.outOfBoundOrders.map((order) => {
          if (order._id === payload) {
            return { ...order, deliveryStatus: apiResponse?.data?.message };
          }
          return order;
        });
        state.isLoading = false;
      });
      toast.success(
        // apiResponse?.data.isVisible
        //   ? `Campus Visibility is enabled`
        //   : `Campus visibility is disabled`
        'Delivery Status Updated!'
      );
    } catch (err) {
      toast.error(err?.response?.data?.message || err.error); // Confirmed
    }
  },
  deleteCategoryAPI: async (id) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.delete(`${LIVE_URL}/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        state.categories = state.categories.filter((_) => _._id !== id);
        state.isLoading = false;
      });
      toast.success('Category Deleted');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  },
  deleteReviewAPI: async (id) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.delete(`${LIVE_URL}/reviews/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        state.reviews = state.reviews.filter((_) => _._id !== id);
        state.isLoading = false;
      });
      toast.success('Review Deleted');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  },
  deleteFeedbackAPI: async (id) => {
    const adminInfo = localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null;

    const token = adminInfo?.token;
    try {
      set((state) => {
        state.isLoading = true;
      });
      const apiResponse = await axios.delete(`${LIVE_URL}/feedback/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      set((state) => {
        state.feedback = state.feedback.filter((_) => _._id !== id);
        state.isLoading = false;
      });
      toast.success('Feedback Deleted');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  },
});

export const getCustomerById = (id) => {
  return (state) => {
    let customer = state.customers.filter((cust) => cust._id === id);

    if (customer) {
      return customer[0];
    }
    return null;
  };
};

export const getMealById = (id) => {
  return (state) => {
    let meal = state.meals.filter((ml) => ml._id === id);

    if (meal) {
      return meal[0];
    }
    return null;
  };
};

export const getCategoryById = (id) => {
  return (state) => {
    let category = state.categories.filter((cat) => cat._id === id);

    if (category) {
      return category[0];
    }
    return null;
  };
};

export const getCampusById = (id) => {
  return (state) => {
    let campus = state.campuses.filter((cat) => cat._id === id);

    if (campus) {
      return campus[0];
    }
    return null;
  };
};

export const getFeedbackById = (id) => {
  return (state) => {
    let feedbk = state.feedback.filter((fbk) => fbk._id === id);

    if (feedbk) {
      return feedbk[0];
    }
    return null;
  };
};

export const getReviewById = (id) => {
  return (state) => {
    let review = state.reviews.filter((rvw) => rvw._id === id);

    if (review) {
      return review[0];
    }
    return null;
  };
};

export const getOrderById = (id) => {
  return (state) => {
    let order = state.orders.filter((ord) => ord._id === id);

    if (order) {
      return order[0];
    }
    return null;
  };
};

export const getOutOfBoundOrderById = (id) => {
  return (state) => {
    let order = state.outOfBoundOrders.filter((ord) => ord._id === id);

    if (order) {
      return order[0];
    }
    return null;
  };
};

export const useStore = create(devtools(immer(store)));
