import { useState } from 'react';
import { Button, useTheme } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { tokens } from '../theme';
import CategoryDeleteDialog from '../scenes/category/CategoryDeleteDialog';

const BtnDeleteDataRow = ({ cat }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          backgroundColor: '#dc3545',
          color: colors.grey[100],
          fontSize: '14px',
          fontWeight: 'bold',
        }}
        display="flex"
        alignItems="center"
        p="4px"
        borderRadius="12px"
        // backgroundColor="#DC3545"
      >
        <DeleteOutlineIcon />
      </Button>
      {open && (
        <CategoryDeleteDialog
          cat={cat}
          open={open}
          onHandleClose={handleClose}
        />
      )}
    </>
  );
};

export default BtnDeleteDataRow;
