import { useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataContacts } from '../../data/mockData';
import Header from '../../components/Header';
import { Avatar, useTheme } from '@mui/material';
// import { useSelector, useDispatch } from 'react-redux';
import { useStore } from '../../store';
// import { useUpdateAdminMutation } from '../../features/auth/adminApiSlice';
// import { setCredentials } from '../../features/auth/authSlice';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';

const Profile = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const { adminInfo } = useSelector((state) => state.auth);
  const adminInfo = useStore((state) => state.auth);
  // const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));

  const updateAuthAdminProfileAPICall = useStore(
    (state) => state.updateAuthAdminProfileAPI
  );

  // const [updateProfile, { data, isError, error, isLoading }] =
  //   useUpdateAdminMutation();

  // useEffect(() => {
  //   if (data) {
  //     localStorage.setItem('adminInfo', JSON.stringify(data));
  //     // navigate('/dashboard');
  //   }
  //   if (isError) {
  //     toast.error(error?.data?.message || error.error);
  //   }
  //   // if (adminInfo) {
  //   //   navigate('/dashboard');
  //   // }
  // }, [data, adminInfo, navigate]);

  const onSubmit = async (values, actions) => {
    try {
      updateAuthAdminProfileAPICall({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        address: values.address,
        token: values.token,
      });
      // const res = await updateProfile({
      //   firstName: values.firstName,
      //   lastName: values.lastName,
      //   email: values.email,
      //   phone: values.phone,
      //   address: values.address,
      //   token: values.token,
      // }).unwrap();
      // dispatch(setCredentials({ ...res }));
      // toast.success('Profile Updated!');
      // navigate('/profile');
    } catch (err) {
      // toast.error(error?.data?.message || error.error);
    }
    // const _vals = { ...values };
    // const formData = new FormData();
    // formData.append('name', _vals.name);
    // formData.append('category', _vals.category);
    // formData.append('price', _vals.price);
    // formData.append('image', _vals.image);
    // formData.append('description', _vals.description);

    // const _values = { ...values, image: values.image.name };

    // await createMealApiCall(formData);
    // actions.resetForm();
    // navigate('/meals');
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: adminInfo,
    validationSchema: profileSchema,
    onSubmit,
  });
  return (
    <Box m="20px">
      <Header
        title="Profile"
        subtitle="View & Update your profile information"
      />

      <Box sx={{ display: 'flex', gap: '50px' }}>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              // justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <Avatar
                sx={{ width: 180, height: 180 }}
                src={adminInfo && adminInfo.img}
              />
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <Button
                  sx={{
                    backgroundColor: colors.greenAccent[800],
                    color: theme.palette.mode === 'dark' && '#f3f3f3',
                  }}
                >
                  Change Picture
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#ff7878',
                    color: theme.palette.mode === 'dark' && '#f3f3f3',
                  }}
                >
                  Delete Picture
                </Button>
              </Box>
            </Box>
            <Box sx={{ alignSelf: 'flex-start' }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  color:
                    theme.palette.mode === 'light'
                      ? colors.grey[500]
                      : '#f3f3f3',
                }}
                variant="h3"
              >
                {adminInfo && adminInfo.firstName}{' '}
                {adminInfo && adminInfo.lastName}
              </Typography>
              <Typography
                sx={{
                  color:
                    theme.palette.mode === 'light'
                      ? colors.grey[500]
                      : '#e3e3e3',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {adminInfo && adminInfo.email}{' '}
                <span
                  style={{
                    height: '10px',
                    width: '10px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    backgroundColor: colors.redAccent[500],
                  }}
                ></span>
                {adminInfo && adminInfo.phone}
              </Typography>
              <Typography
                sx={{
                  color:
                    theme.palette.mode === 'light'
                      ? colors.grey[500]
                      : '#e3e3e3',
                }}
              >
                {adminInfo && adminInfo.address}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 2 }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name="phone"
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                // fullWidth
                variant="filled"
                type="hidden"
                // label="Token"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.token}
                name="token"
                error={!!touched.token && !!errors.token}
                helperText={touched.token && errors.token}
                sx={{ gridColumn: 'span 4' }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                {isSubmitting ? <Spinner color="#888" /> : `Update Profile`}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const profileSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  address: yup.string().required('Address is required'),
  phone: yup.string().required('Phone Number is required'),
  token: yup.string().required(),
});
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  phone: '',
  token: '',
};

export default Profile;
