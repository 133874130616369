import { useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { useStore } from '../../store';
import PreviewImage from './PreviewImage';
import Spinner from '../../components/Spinner';

const AddMeal = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const callGetCategoriesApi = useStore((state) => state.getCategoriesApi);
  const categories = useStore((state) => state.categories);

  const createMealApiCall = useStore((state) => state.createMealApi);

  const navigate = useNavigate();

  useEffect(() => {
    callGetCategoriesApi();
  }, []);

  const onSubmit = async (values, actions) => {
    const _vals = { ...values };
    const formData = new FormData();
    formData.append('name', _vals.name);
    formData.append('category', _vals.category);
    formData.append('price', _vals.price);
    formData.append('image', _vals.image);
    formData.append('description', _vals.description);

    // const _values = { ...values, image: values.image.name };

    await createMealApiCall(formData);
    actions.resetForm();
    navigate('/meals');
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: checkoutSchema,
    onSubmit,
  });
  return (
    <Box m="20px">
      <Header title="CREATE MEAL" subtitle="Create a New Meal" />

      {/* <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => ( */}
      {/* {values.image && <PreviewImage file={values.image} />} */}
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            name="name"
            error={!!touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Price"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.price}
            name="price"
            error={!!touched.price && !!errors.price}
            helperText={touched.price && errors.price}
            sx={{ gridColumn: 'span 2' }}
          />
          <FormControl variant="filled" sx={{ gridColumn: 'span 2' }}>
            <InputLabel id="demo-simple-select-filled-label">
              Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={values.category}
              name="categories"
              onChange={(event) => {
                setFieldValue('category', event.target.value);
              }}
              label="Categories"
              error={!!touched.category && !!errors.category}
              //   helperText={touched.categories && errors.categories}
            >
              {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>  */}
              {categories &&
                categories.map((cat) => (
                  <MenuItem key={cat._id} value={cat._id}>
                    {cat.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            variant="filled"
            type="file"
            // label="Contact Number"
            onBlur={handleBlur}
            onChange={(event) => setFieldValue('image', event.target.files[0])}
            // value={values.image}
            name="image"
            error={!!touched.image && !!errors.image}
            helperText={touched.image && errors.image}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            name="description"
            error={!!touched.description && !!errors.description}
            helperText={touched.description && errors.description}
            sx={{ gridColumn: 'span 4' }}
          />
          {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: 'span 4' }}
              /> */}
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button type="submit" color="secondary" variant="contained">
            {isSubmitting ? <Spinner color="#888" /> : `Create Meal`}
          </Button>
        </Box>
      </form>
      {/* )}
      </Formik> */}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const checkoutSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  price: yup.number().positive().integer().required('Price is Required'),
  category: yup.string().required('Category is Required'),
  //   contact: yup
  //     .string()
  //     .matches(phoneRegExp, 'Phone number is not valid')
  //     .required('required'),
  // image: yup.mixed().nullable().required('Image is required'),
  // .test(
  //   'FILE_SIZE',
  //   'Uploaded file is too big.',
  //   (value) => !value || (value && value.size <= 1024 * 1024)
  // )
  // .test(
  //   'FILE_FORMAT',
  //   'Uploaded file has unsupported format.',
  //   (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  // ),
  description: yup.string().required('Description required'),
});
const initialValues = {
  name: '',
  price: '',
  category: '',
  // image: '',
  description: '',
};

export default AddMeal;
