import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import { useStore } from './store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const navigate = useNavigate();
  const adminInfo = useStore((state) => state.auth);
  // const { adminInfo } = useSelector((state) => state.auth);
  // const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));

  useEffect(() => {
    if (adminInfo) {
      // navigate('/dashboard');
    }
  }, [adminInfo, navigate]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {adminInfo && <Sidebar isSidebar={isSidebar} />}
          <main className="content" style={{ overflow: 'scroll' }}>
            <Topbar setIsSidebar={setIsSidebar} />
            <ToastContainer />
            <Outlet />
            {/* <Routes>
              
            </Routes> */}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
