import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { useStore } from '../../store';
import Spinner from '../../components/Spinner';

const MenuImageForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const { id } = useParams();
  const navigate = useNavigate();
  const updateMealImageAPICall = useStore((state) => state.updateMealImageAPI);

  const onSubmit = async (values, actions) => {
    const _vals = { ...values };
    const formData = new FormData();
    formData.append('_id', id);
    formData.append('img', _vals.img);

    await updateMealImageAPICall(formData);
    actions.resetForm();
    navigate('/menu');
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    // validationSchema: UpdateMealImageSchema,
    onSubmit,
  });
  return (
    <Box m="20px">
      <Header title="MEAL PHOTO" subtitle="Update Meal Photo" />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="file"
            // label="Contact Number"
            onBlur={handleBlur}
            onChange={(event) => setFieldValue('img', event.target.files[0])}
            // value={values.img}
            name="img"
            error={!!touched.img && !!errors.img}
            helperText={touched.img && errors.img}
            sx={{ gridColumn: 'span 4' }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button type="submit" color="secondary" variant="contained">
            {isSubmitting ? <Spinner color="#888" /> : `Update`}
          </Button>
        </Box>
      </form>
      {/* )}
        </Formik> */}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const UpdateMealImageSchema = yup.object().shape({
  // image: yup.mixed().nullable().required('Image is required'),
  // .test(
  //   'FILE_SIZE',
  //   'Uploaded file is too big.',
  //   (value) => !value || (value && value.size <= 1024 * 1024)
  // )
  // .test(
  //   'FILE_FORMAT',
  //   'Uploaded file has unsupported format.',
  //   (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  // ),
  description: yup.string().required('Description required'),
});
const initialValues = {
  // image: '',
};

export default MenuImageForm;
