import * as React from 'react';
import Switch from '@mui/material/Switch';
import Spinner from './Spinner';

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export default function BtnSwitch({ isVisible }) {
  return (
    <div>
      <Switch checked={isVisible} {...label} size="small" />
      {/* <Switch {...label} defaultChecked /> */}
    </div>
  );
}
