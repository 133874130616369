import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

export default function OutOfBoundOrderProductCard({ order }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Card
      sx={{
        // maxWidth: 300,
        backgroundColor: `${
          theme.palette.mode === 'dark' ? colors.primary[500] : '#f3f3f3'
        }`,
      }}
    >
      <CardMedia
        sx={{ height: 150, width: 200, margin: '5px', borderRadius: '12px' }}
        // image="https://media.fooducate.com/comments/images/62815207-5157-AE28-4C64-B8A2C34F0426-12231.jpeg"
        image={order?.product?.image}
        // title={order?.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {order?.product?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Quantity: {order?.quantity}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            backgroundColor: colors.redAccent[500],
            color: '#f3f3f3',
            marginTop: '-10px',
          }}
          size="small"
        >
          £{order?.product?.price}
        </Button>
        {/* <Button
          sx={{ backgroundColor: colors.greenAccent[500], color: '#f3f3f3' }}
          size="small"
        >
          {order?.category}
        </Button> */}
      </CardActions>
    </Card>
  );
}
