import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { getOrderById, useStore } from '../../store';
import Spinner from '../../components/Spinner';
import ReviewGrid from './ReviewGrid';
import OrderProductCard from './OrderProductCard';
import ReviewCard from './ReviewCard';
import OrderInfo from './OrderInfo';

const ShowOrder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();

  const orderToShow = useStore(getOrderById(id));

  return (
    <>
      <Box m="20px">
        <Header title="Order" subtitle="Order Detail" />
        {/* <Link to="/add-campus">
          <ButtonPrimary>Add Campus</ButtonPrimary>
        </Link> */}
        <OrderInfo data={orderToShow} />
        <Box
          m="40px 0 0 0"
          display="flex"
          gap="32px"
          justifyContent="center"
          flexWrap="wrap"
          //   height="75vh"
        >
          {/* <ReviewGrid> */}
          {/* <Grid item xs={6} sm={6} md={6}> */}
          {/* <MenuCard meal={meal} /> */}
          {orderToShow?.orderItems.map((order) => (
            <OrderProductCard key={order?._id} order={order} />
          ))}
          {/* </Grid> */}
          {/* <Grid item xs={6} sm={6} md={6}>
              <ReviewCard
                date={orderToShow?.dateCreated}
                fullName={orderToShow?.customer?.fullName}
                comment={orderToShow?.comment}
              />
            </Grid> */}
          {/* </ReviewGrid> */}
        </Box>
      </Box>
    </>
  );
};

export default ShowOrder;
