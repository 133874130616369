import { useEffect } from 'react';
import { Box, Button, TextField, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { useStore, getCategoryById } from '../../store';
// import {
//   useGetCategoryQuery,
//   useUpdateCategoryMutation,
// } from '../../features/categories/categoryApi';
import Spinner from '../../components/Spinner';
import { tokens } from '../../theme';

const CategoryEditTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { id } = useParams();
  const navigate = useNavigate();

  const categoryToEdit = useStore(getCategoryById(id));
  // const {
  //   data: categoryToEdit,
  //   isLoading: isCategoryLoading,
  //   isError: isCategoryError,
  //   error: categoryError,
  // } = useGetCategoryQuery(id); // Replace '1' with the desired post ID

  // const [updateCategory] = useUpdateCategoryMutation();

  const updateCategoryApiCall = useStore((state) => state.updateCategoryAPI);

  const onSubmit = async (values, actions) => {
    if (!categoryToEdit) return;

    // try {
    //   const updatedCategory = await updateCategory({
    //     ...categoryToEdit,
    //     ...values,
    //   }).unwrap();

    //   // Clear the form
    //   // Deselect the post
    // } catch (err) {

    // }
    try {
      await updateCategoryApiCall({ _id: values._id, name: values.name });
      actions.resetForm();
      navigate('/categories');
    } catch (err) {}
    // await updateCategoryApiCall(values);
    // actions.resetForm();
    // navigate('/categories');
  };

  useEffect(() => {}, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: categoryToEdit,
    validationSchema: checkoutSchema,
    onSubmit,
  });
  return (
    <Box m="20px">
      <Header title="EDIT CATEGORY" subtitle="Edit a Meal Category" />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            name="name"
            error={!!touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            sx={{ gridColumn: 'span 4' }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: '#fff',
            }}
            disabled={isSubmitting}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {isSubmitting ? <Spinner color="#888" /> : `Update Category`}
          </Button>
        </Box>
      </form>
      {/* )}
        </Formik> */}
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});
const initialValues = {
  name: '',
};

export default CategoryEditTable;
