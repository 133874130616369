import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import { useStore } from '../../store';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Typography, Button, TextField, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import Spinner from '../../components/Spinner';
import { tokens } from '../../theme';
// import { useLoginMutation } from '../../features/auth/adminApiSlice';
// import { setCredentials } from '../../features/auth/authSlice';
import { toast } from 'react-toastify';

const HomeScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [login, { data, isError, error }] = useLoginMutation();

  // const { adminInfo } = useSelector((state) => state.auth);
  // const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));

  const adminInfo = useStore((state) => state.auth);

  const authAdminApiCall = useStore((state) => state.authAdminApi);

  const onSubmit = async (values, actions) => {
    // await authAdminApiCall(values);
    // actions.resetForm();
    // navigate('/dashboard');

    try {
      // const res = await login(values).unwrap();
      // dispatch(setCredentials({ ...res }));
      // actions.resetForm();
      // navigate('/dashboard');

      await authAdminApiCall(values);
      actions.resetForm();
      navigate('/dashboard');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    // if (data) {
    //   localStorage.setItem('adminInfo', JSON.stringify(data));
    //   navigate('/dashboard');
    // }
    // if (isError) {
    //   toast.error(error?.data?.message || error.error);
    // }
    if (adminInfo) {
      navigate('/dashboard');
    }
  }, [adminInfo, navigate]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: authSchema,
    onSubmit,
  });
  return (
    <Box
      sx={{
        maxWidth: '100%',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.up('sm')]: {
            maxWidth: '80%',
          },
          [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
          },
          margin: '80px auto',
          padding: '35px 25px',
          border: '1px solid #e3e3e3',
          borderRadius: '9px',
        }}
      >
        <Box sx={{ textAlign: 'center', marginBottom: '25px' }}>
          <Typography
            variant="h1"
            sx={{ color: colors.greenAccent[500], fontWeight: 700 }}
          >
            Welcome Back!
          </Typography>
          <Typography sx={{}}>Log in to your Account</Typography>
        </Box>
        {/* <Header title="EDIT CATEGORY" subtitle="Edit a Meal Category" /> */}
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              marginBottom: '5px',
              '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={!!touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: 'span 4' }}
            />
          </Box>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="password"
              label="Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={!!touched.password && !!errors.password}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: 'span 4' }}
            />
          </Box>
          <Box display="flex" mt="20px">
            <Button
              sx={{
                backgroundColor: colors.redAccent[500],
                color: '#fff',
                width: '100%',
              }}
              disabled={isSubmitting}
              type="submit"
              color="secondary"
              variant="contained"
            >
              {isSubmitting ? (
                <Spinner color="#888" />
              ) : (
                <Typography sx={{ fontWeight: 500 }}>Login</Typography>
              )}
            </Button>
          </Box>
        </form>
        {/* )}
        </Formik> */}
      </Box>
    </Box>
  );
};

const authSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});
const initialValues = {
  email: '',
  password: '',
};

export default HomeScreen;
