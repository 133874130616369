import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Skeleton, useTheme } from '@mui/material';
import { tokens } from '../../theme';

export default function MenuCardSkeleton() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Card
      sx={{
        backgroundColor: `${
          theme.palette.mode === 'dark' ? colors.primary[500] : '#f3f3f3'
        }`,
      }}
    >
      <Skeleton variant="rectangular" animation="wave" height={250} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {/* {meal.name} */}
          <Skeleton animation="wave" width="30%" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Skeleton animation="wave" />
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button
          sx={{ backgroundColor: colors.redAccent[500], color: '#f3f3f3' }}
          size="small"
        >
          {meal.price}
        </Button>
        <Button
          sx={{ backgroundColor: colors.greenAccent[500], color: '#f3f3f3' }}
          size="small"
        >
          {meal.category}
        </Button>
      </CardActions> */}
    </Card>
  );
}
