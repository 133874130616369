import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import { Provider } from 'react-redux';
// import store2 from './store2';
import App from './App';
import HomeScreen from './scenes/home/HomeScreen';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
// import { BrowserRouter } from "react-router-dom";
// ROUTES
import Dashboard from './scenes/dashboard';
import Customer from './scenes/customer';
import Meals from './scenes/meal/meals';
import Invoices from './scenes/invoices';
import Contacts from './scenes/contacts';
import Bar from './scenes/bar';
import Form from './scenes/form';
import Line from './scenes/line';
import Pie from './scenes/pie';
import FAQ from './scenes/faq';
import Geography from './scenes/geography';
import Calendar from './scenes/calendar/calendar';
import Categories from './scenes/category/Categories';
import AddMeal from './scenes/meal/AddMeal';
import Menu from './scenes/menu/menu';
import AddCategory from './scenes/category/AddCategory';
import CategoryDeleteDialog from './scenes/category/CategoryDeleteDialog';
import CategoryEditTable from './scenes/category/CategoryEditTable';
import Profile from './scenes/profile';
import PrivateRoutes from './components/PrivateRoutes';
import Campuses from './scenes/campus/Campuses';
import AddCampus from './scenes/campus/AddCampus';
import CampusEditTable from './scenes/campus/CampusEditTable';
import Reviews from './scenes/review/Reviews';
import ShowReview from './scenes/review/ShowReview';
import Feedback from './scenes/feedback/Feedback';
import ShowFeedback from './scenes/feedback/ShowFeedback';
import ShowCustomer from './scenes/customer/ShowCustomer';
import ShowMeal from './scenes/meal/ShowMeal';
import MealEditTable from './scenes/meal/MealEditTable';
import Admins from './scenes/admin/Admins';
import AddAdmin from './scenes/admin/AddAdmin';
import AdminDeleteDialog from './scenes/admin/AdminDeleteDialog';
import Orders from './scenes/order/Orders';
import OutOfBoundOrders from './scenes/outofbound/OutOfBoundOrders';
import MenuImageForm from './scenes/menu/MenuImageForm';
import ShowOrder from './scenes/order/ShowOrder';
import ShowOutOfBoundOrder from './scenes/outofbound/ShowOutOfBoundOrder';

// import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// const LIVE_URL = 'https://d818-backend.onrender.com/api';
// const LOCAL_URL = 'http://localhost:5000/api';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<App />}>
      <Route path="/login" element={<HomeScreen />} />
      <Route path="" element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/customers" element={<Customer />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/add-admin" element={<AddAdmin />} />
        <Route path="/admins/:id/delete" element={<AdminDeleteDialog />} />
        <Route path="/customers/:id/show" element={<ShowCustomer />} />
        <Route path="/meals" element={<Meals />} />
        <Route path="/meals/:id/show" element={<ShowMeal />} />
        <Route path="/meals/:id/edit" element={<MealEditTable />} />
        <Route path="/add-meal" element={<AddMeal />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/menu/:id/edit" element={<MenuImageForm />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/add-category" element={<AddCategory />} />
        <Route path="/categories/:id/edit" element={<CategoryEditTable />} />
        <Route path="/campuses" element={<Campuses />} />
        <Route path="add-campus" element={<AddCampus />} />
        <Route path="/campuses/:id/edit" element={<CampusEditTable />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/reviews/:id/show" element={<ShowReview />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback/:id/show" element={<ShowFeedback />} />
        <Route
          path="/categories/:id/delete"
          element={<CategoryDeleteDialog />}
        />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:id/show" element={<ShowOrder />} />
        <Route path="/out-of-bound" element={<OutOfBoundOrders />} />
        <Route
          path="/out-of-bound/:id/show"
          element={<ShowOutOfBoundOrder />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/form" element={<Form />} />
        <Route path="/bar" element={<Bar />} />
        <Route path="/pie" element={<Pie />} />
        <Route path="/line" element={<Line />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/geography" element={<Geography />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Provider store={store2}> */}
    <RouterProvider router={router} />
    {/* </Provider> */}
  </React.StrictMode>
);

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
