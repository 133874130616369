import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { getFeedbackById, useStore } from '../../store';
import Spinner from '../../components/Spinner';
import FeedbackCard from './feedbackCard';

const ShowFeedback = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();

  const feedbackToShow = useStore(getFeedbackById(id));

  return (
    <>
      <Box m="20px">
        <Header title="Feedback" subtitle="Feedback Detail" />
        {/* <Link to="/add-campus">
          <ButtonPrimary>Add Campus</ButtonPrimary>
        </Link> */}
        <Box
          m="40px 0 0 0"
          //   height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              // color: colors.greenAccent[300],
              fontSize: '16px',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
              fontSize: '18px',
              // borderBottom: 'none',
              // border: `1px solid ${colors.grey[700]}`,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              // borderTop: 'none',
              // border: `1px solid ${colors.grey[700]}`,
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
            },
            // '& .MuiCheckbox-root': {
            //   color: `${colors.greenAccent[200]} !important`,
            // },
          }}
        >
          {/* <p>{`${feedbackToShow && feedbackToShow.message}`}</p> */}
          <FeedbackCard
            date={feedbackToShow?.updatedAt}
            firstName={feedbackToShow?.firstName}
            lastName={feedbackToShow?.lastName}
            email={feedbackToShow.email}
            phone={feedbackToShow.phone}
            message={feedbackToShow.message}
          />
        </Box>
      </Box>
    </>
  );
};

export default ShowFeedback;
