import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Typography, Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { useStore } from '../../store';
import ButtonPrimary from '../../components/ButtonPrimary';
import Spinner from '../../components/Spinner';
import OutOfBoundOrderActions from './OutOfBoundOrderActions';

const OutOfBoundOrders = () => {
  const orders = useStore((state) => state.outOfBoundOrders);
  const isLoading = useStore((state) => state.isLoading);
  const callGetOutOfBoundOrdersApi = useStore(
    (state) => state.getOutOfBoundOrdersApi
  );
  const callUpdateDeliveryStatus = useStore(
    (state) => state.updateOutOfBoundDeliveryStatus
  );
  const _orders = [];

  // console.log(orders);

  if (orders) {
    orders.forEach((order) => {
      let _order = {
        id: order._id.toString(),
        customer: order?.customer?.fullName,
        orderId: order?.orderId,
        address: order.addr1 ? order.addr1 : order?.campus?.name,
        deliveryStatus: order?.deliveryStatus,
        // subTotal: `£${order?.subTotal}`,
        totalPrice: `£${order?.totalPrice}`,
        date: moment(order?.dateOrdered).format('MMM Do YY, h:mm:ss a'),
      };
      _orders.push(_order);
    });
  }

  useEffect(() => {
    // fetch post automatically due to the hook
    if (orders.length === 0) {
      callGetOutOfBoundOrdersApi();
    }
    // dispatch(fetchCategories());
  }, [orders]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'orderId',
      headerName: 'Order ID',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    // {
    //   field: 'subTotal',
    //   headerName: 'Sub Total',
    //   flex: 1,
    //   cellClassName: 'name-column--cell',
    // },
    {
      field: 'totalPrice',
      headerName: 'Total',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'deliveryStatus',
      headerName: 'Delivery Status',
      flex: 1,
      renderCell: ({ row: { deliveryStatus } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            // p="5px"
            display="flex"
            justifyContent="center"
            // backgroundColor={
            //   role === 'student'
            //     ? colors.greenAccent[600]
            //     : // : access === 'manager'
            //       // ? colors.greenAccent[700]
            //       colors.greenAccent[700]
            // }
            borderRadius="4px"
          >
            {/* {deliveryStatus === 'pending' && 'Pending'}
            {deliveryStatus === 'delivered' && 'Delivered'} */}
            <Typography
              // color={colors.grey[100]}
              sx={{
                ml: '5px',
                backgroundColor: `${
                  deliveryStatus === 'pending' ? '#e74c3c' : '#1abc9c'
                }`,
                color: '#f3f3f3',
                borderRadius: '100px',
                padding: '0px 12px',
              }}
            >
              {deliveryStatus}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            gap="12px"
            borderRadius="4px"
          >
            {/* <CategoryBtnDelete cat={{ id, name }} />
            <CategoryBtnEdit id={id} /> */}
            <OutOfBoundOrderActions
              params={{ id }}
              onDeliveryStatus={callUpdateDeliveryStatus}
              isLoading={isLoading}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box m="20px">
        <Header title="ORDERS" subtitle="Managing Out Of Bounds Orders" />
        {/* <Link to="/add-category">
          <ButtonPrimary>Add Category</ButtonPrimary>
        </Link> */}
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              // color: colors.greenAccent[300],
              fontSize: '14px',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
              fontSize: '18px',
              // borderBottom: 'none',
              // border: `1px solid ${colors.grey[700]}`,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              // borderTop: 'none',
              // border: `1px solid ${colors.grey[700]}`,
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
            },
            // '& .MuiCheckbox-root': {
            //   color: `${colors.greenAccent[200]} !important`,
            // },
          }}
        >
          {/* {isLoading ? (
            <Spinner />
          ) : (
            <DataGrid rows={_orders} columns={columns} loading={isLoading} />
          )} */}
          <DataGrid rows={_orders} columns={columns} loading={isLoading} />
        </Box>
      </Box>
    </>
  );
};

export default OutOfBoundOrders;
