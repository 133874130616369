import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, Preview } from '@mui/icons-material';
// import ReviewDeleteDialog from './ReviewDeleteDialog';
import { useNavigate } from 'react-router-dom';
// import { useValue } from '../../../context/ContextProvider';
// import { deleteRoom } from '../../../actions/room';

const OutOfBoundOrderActions = ({ params, isLoading, onDeliveryStatus }) => {
  // const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        <Tooltip title="Order details">
          <IconButton
            onClick={() => navigate(`/out-of-bound/${params.id}/show`)}
            //   onClick={() => dispatch({ type: 'UPDATE_ROOM', payload: params.row })}
          >
            <Preview />
          </IconButton>
        </Tooltip>
        <Tooltip title="Update delivery status">
          <IconButton onClick={() => onDeliveryStatus(params?.id)}>
            {isLoading ? 'Loading...' : <Edit />}
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Delete this review">
          <IconButton
            onClick={handleOpen}
            //   onClick={() => deleteRoom(params.row, currentUser, dispatch)}
          >
            <Delete />
          </IconButton>
        </Tooltip> */}
      </Box>
      {/* {open && (
        <ReviewDeleteDialog
          rev={params}
          open={open}
          onHandleClose={handleClose}
        />
      )} */}
    </>
  );
};

export default OutOfBoundOrderActions;
