import moment from 'moment';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box } from '@mui/material';

const OrderInfo = ({ data }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <Box display="flex" flexDirection="column" gap="12px">
        <Box display="flex" gap="8px">
          <DateRangeOutlinedIcon />
          <Typography>
            {moment(data?.dateOrdered).format('MMM Do YY, h:mm:ss a')}
          </Typography>
        </Box>
        <Box display="flex" gap="8px">
          <Person2OutlinedIcon />
          <Typography>{data?.customer?.fullName}</Typography>
        </Box>
        <Box display="flex" gap="8px">
          <LocationOnIcon />
          <Typography>{data?.addr1 || data?.campus?.name}</Typography>
        </Box>
        <Box display="flex" gap="8px">
          <ContactPhoneOutlinedIcon />
          <Typography>{data?.phone}</Typography>
        </Box>
        <Box display="flex" gap="8px">
          {/* <Grid3x3OutlinedIcon /> */}
          <Typography>Payment Status:</Typography>
          <Typography>{data?.paymentStatus}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="12px">
        <Box display="flex" gap="8px">
          <Grid3x3OutlinedIcon />
          <Typography>{data?.orderId}</Typography>
        </Box>
        <Box display="flex" gap="8px">
          {/* <Grid3x3OutlinedIcon /> */}
          <Typography>Delivery Status:</Typography>
          <Typography>{data?.deliveryStatus}</Typography>
        </Box>
        <Box display="flex" gap="8px">
          {/* <Grid3x3OutlinedIcon /> */}
          <Typography>Delivery Rate:</Typography>
          <Typography>
            {data.deliveryRate ? `£${data.deliveryRate}` : 'FREE'}
          </Typography>
        </Box>
        <Box display="flex" gap="8px">
          {/* <Grid3x3OutlinedIcon /> */}
          <Typography>Subtotal:</Typography>
          <Typography>£{data?.subTotal}</Typography>
        </Box>
        <Box display="flex" gap="8px">
          {/* <Grid3x3OutlinedIcon /> */}
          <Typography>Total:</Typography>
          <Typography>£{data?.totalPrice}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderInfo;
