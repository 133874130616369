import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { useStore } from '../../store';
import ButtonPrimary from '../../components/ButtonPrimary';
import Spinner from '../../components/Spinner';
import ReviewActions from './ReviewActions';

const Reviews = () => {
  const isLoading = useStore((state) => state.isLoading);
  const reviews = useStore((state) => state.reviews);
  const callGetReviewApi = useStore((state) => state.getReviewApi);

  const _reviews = [];

  if (reviews) {
    reviews.forEach((review) => {
      let _review = {
        id: review._id.toString(),
        customer: review.customer.fullName,
        product: review.product.name,
        comment: review.comment,
        date: moment(review.dateCreated).format('MMM Do YY'),
      };
      _reviews.push(_review);
    });
  }

  useEffect(() => {
    // fetch post automatically due to the hook
    if (reviews.length === 0) {
      callGetReviewApi();
    }
    // dispatch(fetchCategories());
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'product',
      headerName: 'Product',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'date',
      headerName: 'Published Date',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            gap="12px"
            borderRadius="4px"
          >
            {/* <CategoryBtnDelete cat={{ id, name }} />
            <CategoryBtnEdit id={id} /> */}
            <ReviewActions params={{ id }} />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box m="20px">
        <Header title="MEAL REVIEWS" subtitle="Managing Meal's Reviews" />
        {/* <Link to="/add-category">
          <ButtonPrimary>Add Category</ButtonPrimary>
        </Link> */}
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              // color: colors.greenAccent[300],
              fontSize: '16px',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
              fontSize: '18px',
              // borderBottom: 'none',
              // border: `1px solid ${colors.grey[700]}`,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              // borderTop: 'none',
              // border: `1px solid ${colors.grey[700]}`,
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
            },
            // '& .MuiCheckbox-root': {
            //   color: `${colors.greenAccent[200]} !important`,
            // },
          }}
        >
          {/* {isLoading ? (
            <Spinner />
          ) : (
            <DataGrid rows={_reviews} columns={columns} loading={isLoading} />
          )} */}
          <DataGrid rows={_reviews} columns={columns} loading={isLoading} />
        </Box>
      </Box>
    </>
  );
};

export default Reviews;
