import * as React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { tokens } from '../../theme';
import { useStore } from '../../store';
import Spinner from '../../components/Spinner';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #888',
  boxShadow: 5,
  borderRadius: '9px',
  p: 4,
};

export default function AdminDeleteDialog({ cat, open, onHandleClose }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const callDeleteCategoryAPI = useStore((state) => state.deleteCategoryAPI);
  const isLoading = useStore((state) => state.isLoading);
  const handleDeleteCategory = async () => {
    try {
      await callDeleteCategoryAPI(cat.id);
      onHandleClose();
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={onHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ borderTop: `2px solid ${colors.redAccent[500]}` }}
        >
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Delete{' '}
            <span style={{ color: `${colors.redAccent[500]}` }}>
              {cat && cat.name}
            </span>{' '}
            category
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 3 }}>
            Are you sure you want to delete category?
          </Typography>
          <Box
            style={{ display: 'flex', gap: '12px', justifyContent: 'flex-end' }}
          >
            <Button
              onClick={handleDeleteCategory}
              style={{ backgroundColor: '#dc3545' }}
              variant="contained"
            >
              {isLoading ? <Spinner color="#f4f4f4" /> : 'OK'}
            </Button>
            <Button
              style={{ backgroundColor: '#f3f3f3' }}
              onClick={onHandleClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
