import { useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { tokens } from '../../theme';
import { mockTransactions } from '../../data/mockData';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailIcon from '@mui/icons-material/Email';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/Fastfood';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TrafficIcon from '@mui/icons-material/Traffic';
import Header from '../../components/Header';
import LineChart from '../../components/LineChart';
import GeographyChart from '../../components/GeographyChart';
import BarChart from '../../components/BarChart';
import StatBox from '../../components/StatBox';
import ProgressCircle from '../../components/ProgressCircle';
import { useStore } from '../../store';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isLoading = useStore((state) => state.isLoading);

  // Customers
  const callGetCustomersAPI = useStore((state) => state.getCustomersApi);
  const customers = useStore((state) => state.customers);
  const custLength = customers?.length || 0;

  // Meals
  const meals = useStore((state) => state.meals);
  const callGetMealsApi = useStore((state) => state.getMealsApi);
  const mealLength = meals?.length || 0;
  const limitedMeals = meals ? meals.slice(0, 5) : [];

  // Reviews
  const reviews = useStore((state) => state.reviews);
  const callGetReviewApi = useStore((state) => state.getReviewApi);
  const reviewsLength = reviews?.length || 0;

  // Campuses
  const campuses = useStore((state) => state.campuses);
  const callGetCampusesApi = useStore((state) => state.getCampusesApi);
  const campusLength = campuses?.length || 0;

  // Orders
  const orders = useStore((state) => state.orders);
  const callGetOrdersApi = useStore((state) => state.getOrdersApi);

  const flattenData = orders.flatMap((order) =>
    order.orderItems.map((orderItem) => ({
      orderId: orderItem?.product?._id,
      productName: orderItem?.product?.name,
      quantity: orderItem?.quantity,
    }))
  );

  useEffect(() => {
    callGetCustomersAPI();
    callGetMealsApi();
    callGetReviewApi();
    callGetCampusesApi();
    callGetOrdersApi();
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: '10px' }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={custLength}
            subtitle="Customers"
            progress="0.75"
            // increase="+14%"
            icon={
              <PeopleOutlinedIcon
                sx={{ color: colors.redAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={mealLength}
            subtitle="Products"
            progress="0.50"
            // increase="+21%"
            icon={
              <FastfoodOutlinedIcon
                sx={{ color: colors.redAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={reviewsLength}
            subtitle="Review"
            progress="0.30"
            // increase="+5%"
            icon={
              <ReviewsOutlinedIcon
                sx={{ color: colors.redAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={campusLength}
            subtitle="Campuses"
            progress="0.80"
            // increase="+43%"
            icon={
              <SchoolOutlinedIcon
                sx={{ color: colors.redAccent[600], fontSize: '26px' }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        {/* <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenue Generated
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.redAccent[500]}
              >
                £59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: '26px', color: colors.redAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box> */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`2px solid ${colors.grey[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              MEALS
            </Typography>
          </Box>
          {limitedMeals.map((meal) => (
            <Box
              key={meal?._id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`1px solid ${colors.grey[800]}`}
              p="15px"
            >
              <Box>
                <Card
                  sx={{
                    // maxWidth: 300,
                    backgroundColor: `${
                      theme.palette.mode === 'dark'
                        ? colors.primary[500]
                        : '#f3f3f3'
                    }`,
                  }}
                >
                  <CardMedia
                    sx={{
                      height: 70,
                      width: 80,
                      margin: '5px',
                      borderRadius: '12px',
                    }}
                    // image="https://media.fooducate.com/comments/images/62815207-5157-AE28-4C64-B8A2C34F0426-12231.jpeg"
                    image={meal?.image}
                    title={meal?.name}
                  />
                </Card>
              </Box>
              <Box color={colors.grey[100]} sx={{ padding: '0 8px' }}>
                <Typography
                  color={colors.grey[100]}
                  variant="h5"
                  fontWeight="600"
                >
                  {meal?.name}
                </Typography>
                <Typography
                  // color={colors.grey[100]}
                  variant="body2"
                  color="text.secondary"
                  // fontWeight="600"
                >
                  {meal?.description.length > 75
                    ? `${meal.description.slice(0, 75)}...`
                    : meal.description}
                </Typography>
              </Box>
              <Box
                backgroundColor={colors.redAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${meal?.price}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`2px solid ${colors.grey[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {orders.map((order) => (
            <Box
              key={order?._id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`2px solid ${colors.grey[500]}`}
              p="12px"
            >
              <Box>
                <Typography
                  color={colors.redAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {order?.orderId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {order?.customer?.fullName}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>
                {moment(order?.dateOrdered).format('MMM Do YY')}
              </Box>
              <Box
                backgroundColor={colors.redAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${order?.totalPrice}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.redAccent[500]}
              sx={{ mt: '15px' }}
            >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box> */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: '30px 30px 0 30px' }}
          >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} flattenData={flattenData} />
          </Box>
        </Box>
        {/* <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: '15px' }}
          >
            Geography Based Traffic
          </Typography>
          <Box height="200px">
            <GeographyChart isDashboard={true} />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
