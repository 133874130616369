import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { getMealById, useStore } from '../../store';
import Spinner from '../../components/Spinner';
// import ReviewCard from './ReviewCard';
import MealImageCard from './MealImageCard';
import MealGrid from './MealGrid';
import MealCard from './MealCard';
import MealReviewCard from './MealReviewCard';

const ShowMeal = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();

  const mealToShow = useStore(getMealById(id));

  const prodReviews = useStore((state) => state.productReviews);
  const isLoading = useStore((state) => state.isLoading);
  const callGetReviewByProductIdApi = useStore(
    (state) => state.getReviewByProductIdApi
  );

  const _prodReviews = [];

  if (prodReviews) {
    prodReviews.forEach((review) => {
      let _review = {
        id: review._id.toString(),
        customer: review.customer.fullName,
        product: review.product.name,
        comment: review.comment,
        date: moment(review.dateCreated).format('MMM Do YY'),
      };
      _prodReviews.push(_review);
    });
  }

  useEffect(() => {
    // fetch post automatically due to the hook
    // if (prodReviews.length === 0) {
    callGetReviewByProductIdApi(id);
    // }
    // dispatch(fetchCategories());
  }, []);

  return (
    <>
      <Box m="20px">
        <Header title="Meal Detail" />
        {/* <Link to="/add-campus">
          <ButtonPrimary>Add Campus</ButtonPrimary>
        </Link> */}
        <Box
          m="40px 0 0 0"
          //   height="75vh"
        >
          <MealGrid>
            <Grid item xs={6} sm={6} md={6}>
              {/* <MenuCard meal={meal} /> */}
              <MealImageCard data={mealToShow} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              {/* <ReviewCard
                date={mealToShow?.dateCreated}
                fullName={mealToShow?.customer?.fullName}
                comment={mealToShow?.comment}
              /> */}
              <MealCard data={mealToShow} />
            </Grid>
          </MealGrid>
        </Box>
      </Box>

      <Box m="20px">
        <Header title="Reviews" subtitle="Meal Reviews" />

        <Box>
          {_prodReviews &&
            _prodReviews.map((el) => (
              <MealReviewCard
                key={el.id}
                date={el.date}
                fullName={el.customer}
                comment={el.comment}
              />
            ))}
        </Box>
      </Box>
    </>
  );
};

export default ShowMeal;
