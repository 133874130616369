import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useStore } from '../../store';
import ButtonPrimary from '../../components/ButtonPrimary';
import MealActions from './MealActions';

const Meals = () => {
  const isLoading = useStore((state) => state.isLoading);
  const meals = useStore((state) => state.meals);
  const callGetMealsApi = useStore((state) => state.getMealsApi);

  const _meals = [];

  if (meals) {
    meals.forEach((meal) => {
      let _meal = {
        id: meal._id.toString(),
        name: meal.name,
        category: meal.category.name,
        description: meal.description,
        price: `£${meal.price}`,
      };
      _meals.push(_meal);
    });
  }

  useEffect(() => {
    callGetMealsApi();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Name',
      // flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'category',
      headerName: 'Category',
      // flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'price',
      headerName: 'Price',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            gap="12px"
            borderRadius="4px"
          >
            {/* <CategoryBtnDelete cat={{ id, name }} />
            <CategoryBtnEdit id={id} /> */}
            <MealActions params={{ id }} />
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="MEALS" subtitle="Managing the Meals" />
      <Link to="/add-meal">
        <ButtonPrimary>Add Meal</ButtonPrimary>
      </Link>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.redAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.redAccent[500],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={_meals} columns={columns} loading={isLoading} />
      </Box>
    </Box>
  );
};

export default Meals;
