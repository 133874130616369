import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { useStore } from '../../store';
import ButtonPrimary from '../../components/ButtonPrimary';
import BtnDeleteDataRow from '../../components/BtnDeleteDataRow';
import BtnEditDataRow from '../../components/BtnEditDataRow';
import CategoryBtnDelete from './CategoryBtnDelete';
import CategoryBtnEdit from './CategoryBtnEdit';
import Spinner from '../../components/Spinner';
// import { fetchCategories } from '../../features/categories/categorySlice';
// import {
//   useGetCategoriesQuery,
//   useGetCategoryQuery,
// } from '../../features/categories/categoryApi';
import CategoryActions from './AdminActions';
import AdminActions from './AdminActions';

const Admins = () => {
  // const dispatch = useDispatch();
  // const {
  //   data: categories,
  //   isLoading,
  //   isError,
  //   error,
  // } = useGetCategoriesQuery();
  // const {
  //   data: categoryToEdit,
  //   isLoading: isCategoryLoading,
  //   isError: isCategoryError,
  //   error: categoryError,
  // } = useGetCategoryQuery('64d7dc6e3e7b525cfee0258f');

  const admins = useStore((state) => state.admins);
  // const admins = useSelector((state) => state.admins.data);
  const isLoading = useStore((state) => state.isLoading);
  const callGetAdminsApi = useStore((state) => state.getAdminsApi);

  const _admins = [];

  if (admins) {
    admins.forEach((admin) => {
      let _admin = {
        id: admin._id.toString(),
        firstName: admin.firstName,
        lastName: admin.lastName,
        address: admin.address,
        phone: admin.phone,
        email: admin.email,
      };
      _admins.push(_admin);
    });
  }

  useEffect(() => {
    // fetch post automatically due to the hook
    if (admins.length === 0) {
      callGetAdminsApi();
    }
    // dispatch(fetchCategories());
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   flex: 1,
    //   renderCell: ({ row: { id } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         gap="12px"
    //         borderRadius="4px"
    //       >
    //         <AdminActions params={{ id }} />
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Box m="20px">
        <Header title="ADMIN" subtitle="Managing Admins" />
        <Link to="/add-admin">
          <ButtonPrimary>Add Admin</ButtonPrimary>
        </Link>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              // color: colors.greenAccent[300],
              fontSize: '16px',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
              fontSize: '18px',
              // borderBottom: 'none',
              // border: `1px solid ${colors.grey[700]}`,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              // borderTop: 'none',
              // border: `1px solid ${colors.grey[700]}`,
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
            },
            // '& .MuiCheckbox-root': {
            //   color: `${colors.greenAccent[200]} !important`,
            // },
          }}
        >
          {/* {isLoading ? (
            <Spinner />
          ) : (
            <DataGrid rows={_admins} columns={columns} loading={isLoading} />
          )} */}
          <DataGrid rows={_admins} columns={columns} loading={isLoading} />
        </Box>
      </Box>
    </>
  );
};

export default Admins;
