import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { getReviewById, useStore } from '../../store';
import Spinner from '../../components/Spinner';
import ReviewGrid from './ReviewGrid';
import ReviewProductCard from './ReviewProductCard';
import ReviewCard from './ReviewCard';

const ShowReview = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();

  const reviewToShow = useStore(getReviewById(id));

  return (
    <>
      <Box m="20px">
        <Header title="Review" subtitle="Review Detail" />
        {/* <Link to="/add-campus">
          <ButtonPrimary>Add Campus</ButtonPrimary>
        </Link> */}
        <Box
          m="40px 0 0 0"
          //   height="75vh"
        >
          <ReviewGrid>
            <Grid item xs={6} sm={6} md={6}>
              {/* <MenuCard meal={meal} /> */}
              <ReviewProductCard meal={reviewToShow?.product} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <ReviewCard
                date={reviewToShow?.dateCreated}
                fullName={reviewToShow?.customer?.fullName}
                comment={reviewToShow?.comment}
              />
            </Grid>
          </ReviewGrid>
        </Box>
      </Box>
    </>
  );
};

export default ShowReview;
