import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import { mockDataTeam } from '../../data/mockData';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import Header from '../../components/Header';
import { useStore } from '../../store';
import ButtonPrimary from '../../components/ButtonPrimary';
import MenuCard from './MenuCard';
import MenuGrid from './MenuGrid';
import MenuCardSkeleton from './MenuCardSkeleton';
import MenuCategory from './MenuCategory';

const Menu = () => {
  const [category, setCategory] = useState('');
  const meals = useStore((state) => state.meals);
  const isLoading = useStore((state) => state.isLoading);
  const categories = useStore((state) => state.categories);
  const callGetMealsApi = useStore((state) => state.getMealsApi);
  const callGetCategoriesApi = useStore((state) => state.getCategoriesApi);

  const skeletons = [1, 2, 3, 4, 5, 6];

  const _meals = [];

  if (meals) {
    meals.forEach((meal) => {
      let _meal = {
        id: meal._id.toString(),
        name: meal.name,
        category: meal.category.name,
        description: `${
          meal.description.length > 75
            ? `${meal.description.slice(0, 75)}...`
            : meal.description
        }`,
        price: `£${meal.price}`,
        image: meal.image,
      };
      _meals.push(_meal);
    });
  }

  useEffect(() => {
    callGetMealsApi({ categories: category });
    callGetCategoriesApi();
  }, [category]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="MENU" />
      <MenuCategory
        category={category}
        categories={categories || []}
        onSelect={(category) => setCategory(category)}
      />
      <Box sx={{ margin: '0 auto' }}>
        <MenuGrid>
          {isLoading &&
            skeletons.map((el) => (
              <Grid item xs={6} sm={4} md={4} key={el}>
                <MenuCardSkeleton />
              </Grid>
            ))}
          {_meals.map((meal) => (
            <Grid item xs={6} sm={4} md={4} key={meal.id}>
              <MenuCard meal={meal} />
            </Grid>
          ))}
        </MenuGrid>
      </Box>
    </Box>
  );
};

export default Menu;
