import * as React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function FeedbackCard({
  date,
  firstName,
  lastName,
  email,
  phone,
  message,
}) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {moment(date).format('MMM Do YY')}
        </Typography>
        <Typography variant="h2" component="div" fontWeight="bold">
          {/* be{bull}nev{bull}o{bull}lent */}
          {firstName} {lastName}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {email}
          {bull}
          {phone}
        </Typography>
        <Typography variant="body1">{message}</Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
