import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, Preview } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CampusSwitchBtn from './CampusSwitchBtn';
import BtnSwitch from '../../components/BtnSwitch';
import Spinner from '../../components/Spinner';
// import { useValue } from '../../../context/ContextProvider';
// import { deleteRoom } from '../../../actions/room';
import FeedbackDeleteDialog from './FeedbackDeleteDialog';

const FeedbackActions = ({ params }) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   const {
  //     dispatch,
  //     state: { currentUser },
  //   } = useValue();
  return (
    <>
      <Box>
        <Tooltip title="View feedback details">
          <IconButton
            onClick={() => navigate(`/feedback/${params.id}/show`)}
            //   onClick={() => dispatch({ type: 'UPDATE_ROOM', payload: params.row })}
          >
            <Preview />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Edit this campus">
          <IconButton onClick={() => navigate(`/campuses/${params.id}/edit`)}>
            <Edit />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Delete Feedback">
          <IconButton
            onClick={handleOpen}
            //   onClick={() => deleteRoom(params.row, currentUser, dispatch)}
          >
            <Delete />
            {/* <CampusSwitchBtn isVisible={isVisible} /> */}
            {/* {isLoading ? <Spinner /> : <BtnSwitch isVisible={isVisible} />} */}
          </IconButton>
        </Tooltip>
      </Box>
      {open && (
        <FeedbackDeleteDialog
          data={params}
          open={open}
          onHandleClose={handleClose}
        />
      )}
    </>
  );
};

export default FeedbackActions;
