import * as React from 'react';
import moment from 'moment';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CategoryIcon from '@mui/icons-material/Category';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Avatar, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function MealCard({ data }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {/* {moment(date).format('MMM Do YY')} */}
        </Typography>
        <Typography variant="h2" component="div" fontWeight="bold">
          {/* be{bull}nev{bull}o{bull}lent */}
          {data?.name}
        </Typography>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {email}
          {bull}
          {phone}
        </Typography> */}
        <Typography sx={{ marginBottom: '10px' }} variant="body1">
          {data?.description}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'center',
            marginBottom: '8px',
            gap: '5px',
          }}
        >
          <CurrencyPoundIcon sx={{ color: colors.grey[500] }} />
          <Typography variant="body1" color="text.secondary">
            {data?.price}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'center',
            marginBottom: '8px',
            gap: '5px',
          }}
        >
          <CategoryIcon sx={{ color: colors.grey[500] }} />
          <Typography variant="body1" color="text.secondary">
            {data?.category?.name}
          </Typography>
        </Box>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
