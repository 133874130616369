import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CategoryDeleteDialog from './AdminDeleteDialog';
import { tokens } from '../../theme';

const CategoryBtnDelete = ({ cat }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          // backgroundColor: '#dc3545',
          backgroundColor: `${
            theme.palette.mode === 'dark' ? colors.grey[100] : '#f3f3f3'
          }`,
          color: colors.grey[100],
          // fontSize: '14px',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        display="flex"
        alignItems="center"
        p="4px"
        borderRadius="12px"
        // backgroundColor="#DC3545"
      >
        <DeleteOutlineIcon sx={{ color: '#dc3545', fontSize: '20px' }} />
      </Box>
      {open && (
        <CategoryDeleteDialog
          cat={cat}
          open={open}
          onHandleClose={handleClose}
        />
      )}
    </>
  );
};

export default CategoryBtnDelete;
