import { Box, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { useStore } from '../../store';
import Spinner from '../../components/Spinner';
import { tokens } from '../../theme';

const AddAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const navigate = useNavigate();

  const createAdminApiCall = useStore((state) => state.createAdminApi);

  const onSubmit = async (values, actions) => {
    await createAdminApiCall(values);
    actions.resetForm();
    navigate('/admins');
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: adminSchema,
    onSubmit,
  });
  return (
    <Box m="20px">
      <Header title="CREATE ADMIN" subtitle="Create a New Admin" />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="First Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
            name="firstName"
            error={!!touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Last Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastName}
            name="lastName"
            error={!!touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Address"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.address}
            name="address"
            error={!!touched.address && !!errors.address}
            helperText={touched.address && errors.address}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Phone Number"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.phone}
            name="phone"
            error={!!touched.phone && !!errors.phone}
            helperText={touched.phone && errors.phone}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="email"
            label="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            name="email"
            error={!!touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="password"
            label="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            name="password"
            error={!!touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            sx={{ gridColumn: 'span 2' }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: '#fff',
            }}
            disabled={isSubmitting}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {isSubmitting ? <Spinner color="#888" /> : `Create Admin`}
          </Button>
        </Box>
      </form>
      {/* )}
        </Formik> */}
    </Box>
  );
};

const adminSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  address: yup.string().required('Address is required'),
  phone: yup.string().required('Phone Number is required'),
  password: yup.string().min(6).required('Password is required'),
});
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  phone: '',
  password: '',
};

export default AddAdmin;
