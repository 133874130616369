import * as React from 'react';
import { useTheme } from '@emotion/react';
import { tokens } from '../theme';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Spinner({ color }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress style={{ color }} />
    </Box>
  );
}
