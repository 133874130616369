import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { getCustomerById, useStore } from '../../store';
import Spinner from '../../components/Spinner';
// import ReviewCard from './ReviewCard';
import CustomerGrid from './CustomerGrid';
import CustomerProfileCard from './CustomerProfileCard';

const ShowCustomer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();

  const customerToShow = useStore(getCustomerById(id));

  return (
    <>
      <Box m="20px">
        <Header title="Customer Info" />
        {/* <Link to="/add-campus">
          <ButtonPrimary>Add Campus</ButtonPrimary>
        </Link> */}
        <Box
          m="40px 0 0 0"
          //   height="75vh"
        >
          {/* <CustomerGrid> */}
          {/* <Grid item xs={6} sm={6} md={6}> */}
          {/* <MenuCard meal={meal} /> */}
          <CustomerProfileCard data={customerToShow} />
          {/* </Grid> */}
          {/* <Grid item xs={6} sm={6} md={6}> */}
          {/* <ReviewCard
                date={customerToShow?.dateCreated}
                fullName={customerToShow?.customer?.fullName}
                comment={customerToShow?.comment}
              /> */}
          {/* </Grid> */}
          {/* </CustomerGrid> */}
        </Box>
      </Box>
    </>
  );
};

export default ShowCustomer;
