import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box } from '@mui/material';
import { tokens } from '../../theme';

export default function MenuCard({ meal }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  return (
    <Card
      sx={{
        // maxWidth: 300,
        backgroundColor: `${
          theme.palette.mode === 'dark' ? colors.primary[500] : '#f3f3f3'
        }`,
      }}
    >
      <CardMedia
        sx={{ height: 250, margin: '5px', borderRadius: '12px' }}
        // image="https://media.fooducate.com/comments/images/62815207-5157-AE28-4C64-B8A2C34F0426-12231.jpeg"
        image={meal.image}
        title={meal.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {meal.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {meal.description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" gap="12px">
          <Button
            sx={{ backgroundColor: colors.redAccent[500], color: '#f3f3f3' }}
            size="small"
          >
            {meal.price}
          </Button>
          <Button
            sx={{
              backgroundColor: colors.greenAccent[500],
              color: '#f3f3f3',
            }}
            size="small"
          >
            {meal.category}
          </Button>
        </Box>
        <Box>
          <Button onClick={() => navigate(`/menu/${meal?.id}/edit`)}>
            <InsertPhotoOutlinedIcon />
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
