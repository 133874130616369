import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { useStore } from '../../store';
import ButtonPrimary from '../../components/ButtonPrimary';
import BtnDeleteDataRow from '../../components/BtnDeleteDataRow';
import BtnEditDataRow from '../../components/BtnEditDataRow';
import CategoryBtnDelete from './CategoryBtnDelete';
import CategoryBtnEdit from './CategoryBtnEdit';
import Spinner from '../../components/Spinner';
// import { fetchCategories } from '../../features/categories/categorySlice';
// import {
//   useGetCategoriesQuery,
//   useGetCategoryQuery,
// } from '../../features/categories/categoryApi';
import CategoryActions from './CategoryActions';

const Categories = () => {
  // const dispatch = useDispatch();
  // const {
  //   data: categories,
  //   isLoading,
  //   isError,
  //   error,
  // } = useGetCategoriesQuery();
  // const {
  //   data: categoryToEdit,
  //   isLoading: isCategoryLoading,
  //   isError: isCategoryError,
  //   error: categoryError,
  // } = useGetCategoryQuery('64d7dc6e3e7b525cfee0258f');

  const categories = useStore((state) => state.categories);
  // const categories = useSelector((state) => state.categories.data);
  const isLoading = useStore((state) => state.isLoading);
  const callGetCategoriesApi = useStore((state) => state.getCategoriesApi);

  const _categories = [];

  if (categories) {
    categories.forEach((category) => {
      let _category = {
        id: category._id.toString(),
        name: category.name,
      };
      _categories.push(_category);
    });
  }

  useEffect(() => {
    // fetch post automatically due to the hook
    if (categories.length === 0) {
      callGetCategoriesApi();
    }
    // dispatch(fetchCategories());
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    // { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: ({ row: { id, name } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            gap="12px"
            borderRadius="4px"
          >
            {/* <CategoryBtnDelete cat={{ id, name }} />
            <CategoryBtnEdit id={id} /> */}
            <CategoryActions params={{ id, name }} />
          </Box>
        );
      },
    },
    // {
    //   field: 'accessLevel',
    //   headerName: 'Access Level',
    //   flex: 1,
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === 'admin'
    //             ? colors.greenAccent[600]
    //             : access === 'manager'
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === 'admin' && <AdminPanelSettingsOutlinedIcon />}
    //         {access === 'manager' && <SecurityOutlinedIcon />}
    //         {access === 'user' && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <Box m="20px">
        <Header title="CATEGORIES" subtitle="Managing Meal's Categories" />
        <Link to="/add-category">
          <ButtonPrimary>Add Category</ButtonPrimary>
        </Link>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              // color: colors.greenAccent[300],
              fontSize: '16px',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
              fontSize: '18px',
              // borderBottom: 'none',
              // border: `1px solid ${colors.grey[700]}`,
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              // borderTop: 'none',
              // border: `1px solid ${colors.grey[700]}`,
              backgroundColor: colors.redAccent[500],
              color: '#f3f3f3',
            },
            // '& .MuiCheckbox-root': {
            //   color: `${colors.greenAccent[200]} !important`,
            // },
          }}
        >
          {/* {isLoading ? (
            <Spinner />
          ) : (
            <DataGrid rows={_categories} columns={columns} loading={isLoading} />
          )} */}
          <DataGrid rows={_categories} columns={columns} loading={isLoading} />
        </Box>
      </Box>
    </>
  );
};

export default Categories;
