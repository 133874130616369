import { useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/Header';
import { useStore, getMealById } from '../../store';
// import {
//   useGetCategoryQuery,
//   useUpdateCategoryMutation,
// } from '../../features/categories/categoryApi';
import Spinner from '../../components/Spinner';
import { tokens } from '../../theme';

const MealEditTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const { id } = useParams();
  const navigate = useNavigate();

  const mealToEdit = useStore(getMealById(id));
  const _mealToEdit = { ...mealToEdit, category: mealToEdit?.category?._id };

  // const {
  //   data: mealToEdit,
  //   isLoading: isCategoryLoading,
  //   isError: isCategoryError,
  //   error: categoryError,
  // } = useGetCategoryQuery(id); // Replace '1' with the desired post ID

  // const [updateCategory] = useUpdateCategoryMutation();
  const categories = useStore((state) => state.categories);

  const updateMealApiCall = useStore((state) => state.updateMealAPI);

  const onSubmit = async (values, actions) => {
    // if (!mealToEdit) return;
    // try {
    //   const updatedCategory = await updateCategory({
    //     ...mealToEdit,
    //     ...?,
    //   }).unwrap();
    //   // Clear the form
    //   // Deselect the post
    // } catch (err) {
    // }
    try {
      await updateMealApiCall({
        _id: values?._id,
        name: values?.name,
        price: values?.price,
        category: values?.category,
        description: values?.description,
      });
      actions.resetForm();
      navigate('/meals');
    } catch (err) {}
    // await updateMealApiCall(?);
    // actions.resetForm();
    // navigate('/categories');
  };

  // useEffect(() => {}, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: _mealToEdit,
    validationSchema: mealEditSchema,
    onSubmit,
  });
  return (
    <Box m="20px">
      <Header title="EDIT MEAL" subtitle="Edit a Meal" />
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.name}
            name="name"
            error={!!touched?.name && !!errors?.name}
            helperText={touched?.name && errors?.name}
            sx={{ gridColumn: 'span 4' }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Price"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.price}
            name="price"
            error={!!touched?.price && !!errors?.price}
            helperText={touched?.price && errors?.price}
            sx={{ gridColumn: 'span 2' }}
          />
          <FormControl variant="filled" sx={{ gridColumn: 'span 2' }}>
            <InputLabel id="demo-simple-select-filled-label">
              Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={values?.category}
              name="categories"
              onChange={(event) => {
                setFieldValue('category', event.target.value);
              }}
              label="Categories"
              error={!!touched?.category && !!errors?.category}
              //   helperText={touched?.categories && errors?.categories}
            >
              <MenuItem value="">
                <em>{values?.category?.name}</em>
              </MenuItem>
              {categories &&
                categories.map((cat) => (
                  <MenuItem key={cat._id} value={cat._id}>
                    {cat.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.description}
            name="description"
            error={!!touched?.description && !!errors?.description}
            helperText={touched?.description && errors?.description}
            sx={{ gridColumn: 'span 4' }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: '#fff',
            }}
            disabled={isSubmitting}
            type="submit"
            color="secondary"
            variant="contained"
          >
            {isSubmitting ? <Spinner color="#888" /> : `Update Category`}
          </Button>
        </Box>
      </form>
      {/* )}
        </Formik> */}
    </Box>
  );
};

const mealEditSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  // image: yup.string(),
  price: yup.number().positive().integer().required('Price is Required'),
  category: yup.string().required('Category is required'),
});
const initialValues = {
  name: '',
  description: '',
  image: '',
  price: null,
  category: '',
};

export default MealEditTable;
