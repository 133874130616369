import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useStore } from '../store';
// import { useSelector } from 'react-redux';

const PrivateRoutes = () => {
  const adminInfo = useStore((state) => state.auth);
  // const { adminInfo } = useSelector((state) => state.auth);
  // const adminInfo = useStore((state) => state.authAdmin);
  //   return adminInfo ? <Outlet /> : <Navigate to="/login" replace />;
  return adminInfo ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoutes;
