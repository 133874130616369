import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, Preview } from '@mui/icons-material';
import CategoryDeleteDialog from './CampusDeleteDialog';
import { useNavigate } from 'react-router-dom';
import CampusSwitchBtn from './CampusSwitchBtn';
import BtnSwitch from '../../components/BtnSwitch';
import Spinner from '../../components/Spinner';
// import { useValue } from '../../../context/ContextProvider';
// import { deleteRoom } from '../../../actions/room';

const CampusActions = ({
  params,
  isLoading,
  isVisible,
  selectedItemId,
  onHandleSelectedItemId,
}) => {
  // const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const handleForceRerender = () => {
  //   // Change the `rerenderKey` value to trigger the effect.
  //   forceRerender((prevKey) => {
  //     return prevKey + 1;
  //   });
  // };
  const handleSelectedItemId = () => {
    onHandleSelectedItemId((id) => (params?.id === id ? '' : params.id));
    // onHandleSelectedItemId(params?.id);
    // handleForceRerender();
  };

  //   const {
  //     dispatch,
  //     state: { currentUser },
  //   } = useValue();
  return (
    <>
      <Box>
        {/* <Tooltip title="View category details">
          <IconButton
            onClick={() => alert('Preview')}
            //   onClick={() => dispatch({ type: 'UPDATE_ROOM', payload: params.row })}
          >
            <Preview />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Edit this campus">
          <IconButton onClick={() => navigate(`/campuses/${params.id}/edit`)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Update Visibility">
          <IconButton
            onClick={handleSelectedItemId}
            //   onClick={() => deleteRoom(params.row, currentUser, dispatch)}
          >
            {/* <Delete /> */}
            {/* <CampusSwitchBtn isVisible={isVisible} /> */}
            {isLoading && selectedItemId === params?.id ? (
              <Spinner />
            ) : (
              <BtnSwitch isVisible={isVisible} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {/* {open && (
        <CategoryDeleteDialog
          cat={params}
          open={open}
          onHandleClose={handleClose}
        />
      )} */}
    </>
  );
};

export default CampusActions;
