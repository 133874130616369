import { Button, useTheme } from '@mui/material';
import { tokens } from '../theme';

const ButtonPrimary = ({ children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Button
      sx={{
        backgroundColor: colors.redAccent[500],
        color: '#f3f3f3',
        fontSize: '14px',
        fontWeight: 'bold',
        padding: '10px 20px',
        textDecoration: 'none',
      }}
    >
      {children}
    </Button>
  );
};

export default ButtonPrimary;
